
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import { data } from "@/shared/policy/privacy/privacy_v1.0.0";
export default defineComponent({
  name: "PrivacyV1",
  setup() {
    useHead({ title: data.sitemapTitle });
    return {
      data
    };
  }
});
